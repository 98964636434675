import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import cryptoRandomString from 'crypto-random-string';
import {RootStateType} from './store';
import {setAppStatusAC, setMessageTC} from './appReducer';
import {base64Upload, fileUpload, getVisaData, sendApplicantObject} from '../api/firebaseAPI';
import {getCurrentDateTime, mailJsAPI, updateTimeStamp} from '../api/api';
import Mustache from 'mustache';
import {newPhotoTemplate, paymentSuccessTemplate, toAdminTemplate} from '../data/mailTemplate';
import {getVisaTC} from './visaCheckReducer';
import axios from 'axios'
import moment from 'moment';


export type ApplicantDataType = {
  //Technical type
  [index: string]: any

  fullName: string
  male: string
  citizenship: string
  familyStatus: string
  partnerCitizenship?: string
  mainApplicantIs: string
  religion: string
  yearIncome: string
  education: string
  position: string

  passportCopyFiles: Array<string>
  passportCountry: string
  passportIssuedBy: string
  passportNumber: string
  passportData: string
  passportExpire: string
  race: string
  birthPlace: string
  birthCountry: string
  birthDate: string

  residenceCountry: string
  residenceCity: string
  residenceAddress: string
  residenceIndex: string

  fliesFrom: string
  flightInNumber: string
  flightInDate: string
  fliesTo: string
  flightOutNumber: string
  flightOutDate: string

  hasVisaBefore: string
  hasVisaBeforeData: Array<string>
  hasLivedInNonResidenceCountry: string
  hasLivedInNonResidenceCountryData: Array<{ country: string, date: string, address: string }>
  hasDeported: string
  hasDeportedData: string
  hasConvicted: string
  hasConvictedData: string
  hasVisaReject: string
  hasVisaRejectData: Array<string>
  hasAnotherPassport: string
  hasAnotherPassportData: Array<string>
  hasVaccine: string
  hasVaccineData: Array<string>

  photoFiles: Array<string>
  signFiles: Array<string>
  inTicketsFiles: Array<string>
  outTicketsFiles: Array<string>
  additionFiles: Array<string>
  workPlaceFiles: Array<string>
  bookingHotel: Array<string>
  hotelsData: Array<{
    hotelName: string,
    arrivalDate: string,
    departureDate: string,
    hotelAddress: string,
    bookingHotel: Array<string>
  }>
  additionalServiceData: {
    serviceNames: Array<string>
  }

  importantDataToCheck: Partial<ImportantDataType>
}

export type VisaPageDataType = {
  [index: string]: any
  uid: string | undefined,
  tel: string
  email: string
  service: string
  visitPurpose: string
  fullPrice: number
  numberOfApplicants: string
  visa_status: visaStatus | ''
  file: Array<string>
  applicantsData: [] | Array<ApplicantDataType>
  appDate: string
  commentData: string
  badPhotos?: Array<{ fullName: string, isChecked: boolean }>
  pureInc: number
  paymentProcessed: boolean
  totalPrice: {
    curr: string
    value: number
  }
  debt: {
    curr: string
    value: number
  }
  paymentData?: {
    date: string
    paymentType: string,
    currency: string,
    value: number,
    receipt: Array<string>
  }
  accountingData?: Array<AccountingData>
  serviceData: {
    isExpress: string,
    withHotel: string,
    entries: number
  }
  ruCardPreference?: 'Леша' | 'Ира'
  additionalService: {
    hotel: number;
    hotelData?: Array<{
      hotelName: string;
      bookingRef: string;
      issuedDate: string;
      settleInDate: string;
      settleOutDate: string;
      bookingName: string;
      roomType: string;
      hotelAddress: string;
      contactTel: string;
      file: string;
    }>;
    arrivalCard: number;
    arrivalCardData?: Array<string>;
    insurance: number;
    insuranceData?: Array<string>;
  };
}

export type visaStatus =
  | 'отложенная подача'
  | 'в обработке'
  | 'ожидает оплаты'
  | 'проверка оплаты'
  | 'оплачено'
  | 'одобрено'
  | 'отказ'
  | 'плохое фото'
  | 'заполняется'
  | 'в работе'

export type FeesDataType = {
  name: string
  value: number
  curr: string
  rate: number
  isPersonalSpend: boolean;
}

export type AccountingData = {
  name: string
  paymentType: string
  date: string
  value: number
  currency: string
  rates: rateObjType
  fees: Array<FeesDataType>
  receipt: Array<string>
  income: number
}

export type rateObjType = {
  usdRubRate: number
  usdSgdRate: number
}

type FirstPageDataType = {
  service: string
  email: string
  tel: string
  visitPurpose: string
  numberOfApplicants: string
  serviceData: {
    isExpress: string,
    withHotel: string,
    entries: number | undefined
  }
}

export type ImportantDataType = {
  [index: string]: any
  hasVisaBefore: string
  hasVisaFiles: Array<string>
  hasLivedInNonResidenceCountry: string
  hasLivedInNonResidenceCountryDates: Array<string>
  hasDeported: string
  hasDeportedDescription: string
  hasConvicted: string
  hasConvictedDescription: string
  hasVisaReject: string
  hasVisaRejectFiles: Array<string>
  hasAnotherPassport: string
  hasAnotherPassportFiles: Array<string>
}

class ApplicantData {
  fullName: string
  male: string
  citizenship: string
  familyStatus: string
  mainApplicantIs: string
  religion: string
  yearIncome: string
  education: string
  position: string

  passportCopyFiles: Array<string>
  passportCountry: string
  passportIssuedBy: string
  passportNumber: string
  passportData: string
  passportExpire: string
  birthDate: string
  birthCountry: string
  birthPlace: string
  race: string

  residenceCountry: string
  residenceCity: string
  residenceAddress: string
  residenceIndex: string

  fliesFrom: string
  flightInNumber: string
  flightInDate: string
  fliesTo: string
  flightOutNumber: string
  flightOutDate: string

  hasVisaBefore: string
  hasVisaBeforeData: Array<string>
  hasLivedInNonResidenceCountry: string
  hasLivedInNonResidenceCountryData: Array<{ country: string, date: string, address: string }>

  hasDeported: string
  hasDeportedData: string
  hasConvicted: string
  hasConvictedData: string
  hasVisaReject: string
  hasVisaRejectData: Array<string>
  hasAnotherPassport: string
  hasAnotherPassportData: Array<string>
  hasVaccine: string
  hasVaccineData: Array<string>

  importantDataToCheck: Partial<ImportantDataType>

  photoFiles: Array<string>
  signFiles: Array<string>
  inTicketsFiles: Array<string>
  outTicketsFiles: Array<string>
  additionFiles: Array<string>
  workPlaceFiles: Array<string>
  bookingHotel: Array<string>
  hotelsData: Array<{
    hotelName: string,
    arrivalDate: string,
    departureDate: string,
    hotelAddress: string,
    bookingHotel: Array<string>
  }>

  earlyVisaFiles: Array<string>
  rejectFiles: Array<string>
  anotherPassportOrNameFiles: Array<string>
  additionalServiceData: {
    serviceNames: Array<string>
  }

  constructor() {

    this.fullName = ''
    this.male = ''
    this.citizenship = ''
    this.familyStatus = ''
    this.mainApplicantIs = ''
    this.religion = ''
    this.yearIncome = ''
    this.education = ''
    this.position = ''

    this.passportCopyFiles = []
    this.passportCountry = ''
    this.passportIssuedBy = ''
    this.passportNumber = ''
    this.passportData = ''
    this.passportExpire = ''
    this.birthDate = ''
    this.birthCountry = ''
    this.birthPlace = ''
    this.race = ''

    this.residenceCountry = ''
    this.residenceCity = ''

    this.residenceAddress = ''
    this.residenceIndex = ''
    this.fliesFrom = ''


    this.flightInNumber = ''

    this.flightInDate = ''

    this.fliesTo = ''
    this.flightOutNumber = ''

    this.flightOutDate = ''

    this.hasVisaBefore = ''
    this.hasVisaBeforeData = []

    this.hasLivedInNonResidenceCountry = ''
    this.hasLivedInNonResidenceCountryData = [
      {country: '', date: '', address: ''}
    ]

    this.hasVaccine = ''
    this.hasVaccineData = []
    this.hasDeported = ''
    this.hasDeportedData = ''
    this.hasConvicted = ''
    this.hasConvictedData = ''
    this.hasVisaReject = ''
    this.hasVisaRejectData = []
    this.hasAnotherPassport = ''
    this.hasAnotherPassportData = []
    this.photoFiles = []
    this.signFiles = []
    this.inTicketsFiles = []
    this.outTicketsFiles = []
    this.bookingHotel = []
    this.additionFiles = []
    this.workPlaceFiles = []
    this.earlyVisaFiles = []
    this.rejectFiles = []
    this.anotherPassportOrNameFiles = []
    this.hotelsData = [
      {hotelName: '', arrivalDate: '', departureDate: '', hotelAddress: '', bookingHotel: []}
    ]
    this.additionalServiceData = {
      serviceNames: []
    }
    this.importantDataToCheck = {
      hasVisaBefore: '',
      hasVisaBeforeData: [],
      hasLivedInNonResidenceCountry: '',
      hasLivedInNonResidenceCountryData: '',
      hasDeported: '',
      hasDeportedData: '',
      hasConvicted: '',
      hasConvictedData: '',
      hasVisaReject: '',
      hasVisaRejectData: [],
      hasAnotherPassport: '',
      hasAnotherPassportData: []
    }
  }
}

export const sendFullDataTC = createAsyncThunk('visa_page/sendFullData', async (param, thunkAPI) => {
  thunkAPI.dispatch(setAppStatusAC({status: 'loading'}))
  await thunkAPI.dispatch(updateApplicationField({visa_status: 'в обработке'}))
  const state = thunkAPI.getState() as RootStateType;
  const {
    fullPrice,
    tel,
    email,
    numberOfApplicants,
    uid,
    service,
    visa_status,
    applicantsData,
    commentData
  } = state.visa_page
  const {fullName} = state.visa_page.applicantsData[0]
  const res = await sendApplicantObject(state.visa_page)
  let htmlData: any = {
    applicants: applicantsData.map((obj) => ({
      ...obj,
      isMarried: obj.familyStatus === 'Женат/замужем',
      hasLivedInNonResidenceCountryData: obj.hasLivedInNonResidenceCountryData.reduce((acc, el) => acc + el.country + ' ' + el.date + ' ' + el.address + ' <br/> ', '')
    }))
  }

  const html = Mustache.render(toAdminTemplate, htmlData);
  await mailJsAPI.sendCompleteApplicant({
    uid,
    fullName,
    email,
    numberOfApplicants,
    tel,
    service,
    fullPrice,
    visa_status,
    commentData,
    html
  })
  mailJsAPI.sendCompleteToUser({fullName, fullPrice, email, numberOfApplicants, uid, service, commentData})
  updateTimeStamp('applicationsTimestamp')
  thunkAPI.dispatch(setAppStatusAC({status: 'idle'}))
  return res
})
export const getVisaForBadPhotoTC = createAsyncThunk('visa_page/getVisaForBadPhoto', async (uid: string, {
  dispatch,
  rejectWithValue
}) => {
  dispatch(setAppStatusAC({status: 'loading'}))
  try {
    let res = await getVisaData(uid) as VisaPageDataType | undefined
    if (res && res.badPhotos && res.badPhotos.length) {
      res.applicantsData = res.applicantsData.map((el, ind) => {
        if (res?.badPhotos && res.badPhotos[ind].isChecked) {
          return {...el, photoFiles: []}
        }
        return el
      })
    }
    return res
  } catch {
    return rejectWithValue(null)
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})
export const sendBadPhotosCompleteTC = createAsyncThunk('visa_page/sendBadPhotosComplete', async (param, {
  dispatch,
  getState
}) => {
  dispatch(setAppStatusAC({status: 'loading'}))
  await dispatch(updateApplicationField({visa_status: 'в обработке'}))
  const state = getState() as RootStateType;
  const names: { arr: Array<{ photo: string, fullName: string }> } = {arr: []}
  state.visa_page.badPhotos?.forEach((el, index) => el.isChecked && names.arr.push({
    photo: state.visa_page.applicantsData[index].photoFiles[0],
    fullName: el.fullName
  }))
  let html = Mustache.render(newPhotoTemplate, names)
  await dispatch(delApplicationBadPhoto())
  await sendApplicantObject(state.visa_page)
  mailJsAPI.sendBadPhotoComplete({
    uid: state.visa_page.uid,
    email: state.visa_page.email,
    newPhotosAndNamesHtml: html
  })
  updateTimeStamp('applicationsTimestamp')
  dispatch(setAppStatusAC({status: 'idle'}))
})

export const paymentFileAddTC = createAsyncThunk('visa_page/paymentFileAdd', async (params: {
  files: FileList
}, thunkAPI) => {
  const {dispatch, getState} = thunkAPI
  const state = getState() as RootStateType;
  const {email} = state.visa_page

  dispatch(setAppStatusAC({status: 'loading'}))
  try {
    let res: Array<string> = []
    for (let i = 0; i < params.files.length; i++) {
      res = [...res, await fileUpload(`receipts/${email}`, params.files[i])]
    }
    dispatch(setMessageTC({message: 'Файл загружен успешно', isError: false}))
    return res
  } catch {
    dispatch(setMessageTC({message: 'не удалось загрузить файл', isError: true}))
    return
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})

/*export const paymentSuccessTC = createAsyncThunk('visa_page/paymentSuccess', async (params: { uid: string | undefined, email: string, paymentType: string, fullPrice: string, imagesArr: Array<string>, value: number, currency: string }, {
    dispatch,
    getState,
    rejectWithValue
}) => {
    dispatch(setAppStatusAC({status: 'loading'}))
    const {uid, email, fullPrice, value, imagesArr, paymentType, currency} = params
    try {
        if (imagesArr.length) {
            const date = await getCurrentDateTime()
            await dispatch(updateApplicationPayment({
                value,
                paymentType,
                currency,
                receipt: imagesArr,
                date
            }))
            await dispatch(updateApplicationField({visa_status: 'проверка оплаты'}))
            const state = getState() as RootStateType;
            await sendApplicantObject(state.visa_page)
            const receipts: { arr: Array<{ photo: string, receiptCount: number }> } = {
                arr: imagesArr.map((el, index) => ({photo: el, receiptCount: index + 1}))
            }
            let paymentReceiptsHtml = Mustache.render(paymentSuccessTemplate, receipts)
            await mailJsAPI.sendPaymentSuccessToAdmin({uid, email, fullPrice, paymentType, paymentReceiptsHtml})
            await axios.post('https://sentosatelegrambot-production.up.railway.app',
                {
                    fullName: state.visa_page.applicantsData[0].fullName,
                    uid,
                    email,
                    fullPrice,
                    value,
                    paymentType,
                    currency,
                    receipt: imagesArr,
                    date,
                    appDate: state.visa_page.appDate
                }
            )
            return imagesArr
        } else {
            dispatch(setMessageTC({message: `Загрузите скриншот`, isError: true}))
        }
    } catch {
        dispatch(setMessageTC({message: `Упс, какая-то ошибка`, isError: true}))
        rejectWithValue(null)
    } finally {
        dispatch(setAppStatusAC({status: 'idle'}))
    }
})*/
export const accountingSuccessTC = createAsyncThunk('visa_page/accountingSuccess', async (params: {
  uid: string | undefined,
  email: string,
  name: string,
  paymentType: string,
  fullPrice: string,
  imagesArr: Array<string>,
  value: number,
  currency: string,
  rates: rateObjType,
  numberOfApplicants: string
}, {
                                                                                            dispatch,
                                                                                            getState,
                                                                                            rejectWithValue
                                                                                          }) => {
  dispatch(setAppStatusAC({status: 'loading'}))
  const {uid, email, fullPrice, value, imagesArr, paymentType, currency, rates, name, numberOfApplicants} = params
  try {
    if (imagesArr.length) {
      const date = await getCurrentDateTime()
      let income = value
      if (currency === 'RUB') {
        income /= (rates.usdRubRate - 10) / rates.usdSgdRate
      } else if (currency === '$') {
        income *= rates.usdSgdRate
      } else if (paymentType === 'Stripe') {
        income = income * 0.966 - 0.5
      }

      await dispatch(updateAccountingData({
        name,
        fees: [
          {
            name: 'VISA FEE',
            value: +numberOfApplicants * 30,
            curr: 'SGD',
            isPersonalSpend: false,
            rate: 1
          },
          {
            name: 'VISA PROCESSING',
            value: +numberOfApplicants * 5,
            curr: 'SGD',
            isPersonalSpend: false,
            rate: 1
          },
        ],
        income,
        rates,
        value,
        paymentType,
        currency,
        receipt: imagesArr,
        date
      }))
      await dispatch(updateApplicationField({visa_status: 'проверка оплаты'}))
      const state = getState() as RootStateType;
      await sendApplicantObject(state.visa_page)
      const receipts: { arr: Array<{ photo: string, receiptCount: number }> } = {
        arr: imagesArr.map((el, index) => ({photo: el, receiptCount: index + 1}))
      }
      let paymentReceiptsHtml = Mustache.render(paymentSuccessTemplate, receipts)
      await mailJsAPI.sendPaymentSuccessToAdmin({uid, email, fullPrice, paymentType, paymentReceiptsHtml})
      await axios.post('https://sentosatelegrambot-production.up.railway.app',
        {
          fullName: state.visa_page.applicantsData[0].fullName,
          uid,
          email,
          fullPrice,
          value,
          paymentType,
          currency,
          receipt: imagesArr,
          date,
          appDate: state.visa_page.appDate
        }
      )
      updateTimeStamp('applicationsTimestamp')
      return imagesArr
    } else {
      dispatch(setMessageTC({message: `Загрузите скриншот`, isError: true}))
    }
  } catch {
    dispatch(setMessageTC({message: `Упс, какая-то ошибка`, isError: true}))
    rejectWithValue(null)
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})
/*export const delAccounting = createAsyncThunk('visa_page/delAccounting', async (params, {getState, dispatch}) => {
    await dispatch(updateApplicationField({accountingData: [], pureInc: 0}))
    const state = getState() as RootStateType;
    await sendApplicantObject(state.visa_page)
})*/
export const fileAddTC = createAsyncThunk('visa_page/fileAdd', async (params: { files: FileList }, thunkAPI) => {
  const {dispatch, getState} = thunkAPI
  const state = getState() as RootStateType;
  const {email} = state.visa_page

  dispatch(setAppStatusAC({status: 'loading'}))
  try {
    let res: Array<string> = []
    for (let i = 0; i < params.files.length; i++) {
      res = [...res, await fileUpload(email, params.files[i])]
    }
    dispatch(setMessageTC({message: 'Файл загружен успешно', isError: false}))
    return res
  } catch {
    dispatch(setMessageTC({message: 'не удалось загрузить файл', isError: true}))
    return
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})

export const uploadSignFromBase64TC = createAsyncThunk('visa_page/uploadFromBase64', async (params: {
  base64: string,
  index: number
}, thunkAPI) => {
  const {dispatch, getState} = thunkAPI
  const state = getState() as RootStateType;
  const {email} = state.visa_page
  dispatch(setAppStatusAC({status: 'loading'}))
  try {
    const res = await base64Upload('visa_page', params.base64, `${email}_${params.index}.png`)
    if (res) {
      dispatch(updateApplicantField({index: params.index, fieldName: 'signFiles', value: [res]}))
      dispatch(setMessageTC({message: 'Файл загружен успешно', isError: false}))
      return true
    }
    return false
  } catch {
    dispatch(setMessageTC({message: 'не удалось загрузить файл', isError: true}))
    return
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})

export const additionServiceTC = createAsyncThunk('visa_page/additionService', async (params: {
  serviceName: 'arrivalCard' | 'hotel' | 'insurance',
  value: number,
  isChecked?: boolean,
  applicantIndex?: number
}, thunkAPI) => {
  const {serviceName, value, applicantIndex, isChecked} = params
  const {dispatch, getState} = thunkAPI
  const state = getState() as RootStateType
  const {fullPrice, numberOfApplicants, applicantsData, additionalService} = state.visa_page
  if (serviceName === 'insurance') {
    const calcPrice = (outData: string, inDate: string) => {
      const days = moment(outData).diff(inDate, 'days') + 1

      return days < 6
        ? 15
        : 15 + (days - 5) * 2
    }
    if (additionalService.insurance === 0) {
      let currPrice = fullPrice
      applicantsData.forEach((_, index) => {
        dispatch(updateApplicantField({
          index,
          fieldName: 'additionalServiceData',
          value: {serviceNames: [...applicantsData[index].additionalServiceData.serviceNames, 'insurance']}
        }))
        currPrice += calcPrice(applicantsData[index].flightOutDate, applicantsData[index].flightInDate)
        dispatch(updateApplicationField({fullPrice: currPrice}))
      })
    } else {
      if (isChecked) {
        (applicantIndex !== undefined) && dispatch(updateApplicantField({
          index: applicantIndex,
          fieldName: 'additionalServiceData',
          value: {serviceNames: [...applicantsData[applicantIndex].additionalServiceData.serviceNames, 'insurance']}
        }))
        await dispatch(updateApplicationField({fullPrice: fullPrice + calcPrice(applicantsData[applicantIndex as number].flightOutDate, applicantsData[applicantIndex as number].flightInDate)}))
      } else {
        (applicantIndex !== undefined) && dispatch(updateApplicantField({
          index: applicantIndex,
          fieldName: 'additionalServiceData',
          value: {serviceNames: applicantsData[applicantIndex].additionalServiceData.serviceNames.filter(el => el !== 'insurance')}
        }))
        await dispatch(updateApplicationField({fullPrice: fullPrice - calcPrice(applicantsData[applicantIndex as number].flightOutDate, applicantsData[applicantIndex as number].flightInDate)}))
      }
    }
  }
  if (serviceName === 'arrivalCard') {
    const newPrice = value === 0 ? fullPrice - (+numberOfApplicants * 10) : fullPrice + (+numberOfApplicants * 10)
    value !== 0
      ? applicantsData.forEach((_, index) => {
        dispatch(updateApplicantField({
          index,
          fieldName: 'additionalServiceData',
          value: {serviceNames: [...applicantsData[index].additionalServiceData.serviceNames, 'arrivalCard']}
        }))
      })
      : applicantsData.forEach((_, index) => {
        dispatch(updateApplicantField({
          index,
          fieldName: 'additionalServiceData',
          value: {serviceNames: applicantsData[index].additionalServiceData.serviceNames.filter(el => el !== 'arrivalCard')}
        }))
      })

    await dispatch(updateApplicationField({fullPrice: newPrice}))
  }
  await dispatch(updateApplicationAdditionService({value, serviceName}))
})

// initial objects set in store
export const setVisaPageDataTC = createAsyncThunk('visa_page/setData', async (value: FirstPageDataType, thunkAPI) => {
  thunkAPI.dispatch(setAppStatusAC({status: 'loading'}))
  const price = +value.numberOfApplicants * +value.service.split('-')[1]
  let state = thunkAPI.getState() as RootStateType;
  const date = await getCurrentDateTime()

  await thunkAPI.dispatch(setVisaPageFormikDataAC({
    ...value,
    fullPrice: price,
    visa_status: 'заполняется',
    appDate: state.visa_page.appDate || date
  }))
  state = thunkAPI.getState() as RootStateType;
  const visaFirstPageData = state.visa_page
  await sendApplicantObject(visaFirstPageData)
  updateTimeStamp('applicationsTimestamp')
  thunkAPI.dispatch(setAppStatusAC({status: 'idle'}))
})

//fields checkers
export const filedCheckTC = createAsyncThunk('visa_page/checker', (value: Partial<ApplicantDataType>, {
  dispatch,
  getState
}) => {
  const state = getState() as RootStateType;
  const applicantsData = state.visa_page.applicantsData
  let res
  for (let key in value) {
    res = applicantsData.every(el => {
      if (typeof (el[key]) === 'object') {
        return el[key].length
      } else {
        return key === 'partnerCitizenship' && el.familyStatus !== 'Женат/замужем' ? true : el[key]
      }
    })
    if (!res) {
      dispatch(setMessageTC({message: `Заполните поля у всех заявителей`, isError: true}))
      return
    }
  }
  return res
})

export const page6Checker = createAsyncThunk('visa_page/page6_checker', (param, {
  dispatch,
  getState
}) => {
  const state = getState() as RootStateType;
  const applicantsData = state.visa_page.applicantsData
  let res = true
  applicantsData.forEach(el => {
      let obj = el.importantDataToCheck
      for (let key in obj) {
        if (typeof (obj[key]) === 'object') {
          if (!obj[key].length) {
            res = false
            break
          }
        } else {
          if (!obj[key]) {
            res = false
            break
          }
        }
      }
    }
  )
  if (!res) {
    dispatch(setMessageTC({message: `Заполните поля у всех заявителей`, isError: true}))
  }
  return res
})

export const hotelsDataChecker = createAsyncThunk('visa_page/hotelsDataChecker', (param, {
  dispatch,
  getState
}) => {
  const state = getState() as RootStateType;
  const applicantsData = state.visa_page.applicantsData
  let res = true
  applicantsData.forEach(el => {
      let arr = el.hotelsData
      arr.forEach(obj => {
        for (let key in obj) {
          if (key === 'bookingHotel') {
            continue
            // @ts-ignore
          } else if (!obj[key]) {
            res = false
            break
          }
        }
      })
    }
  )
  if (!res) {
    dispatch(setMessageTC({message: `Заполните поля у всех заявителей`, isError: true}))
  }
  return res
})


const slice = createSlice({
    name: 'visa_page',
    initialState: {
      uid: undefined,
      service: '',
      email: '',
      tel: '',
      visitPurpose: '',
      numberOfApplicants: '',
      fullPrice: 0,
      visa_status: '',
      file: [],
      applicantsData: [],
      appDate: '',
      commentData: '',
      pureInc: 0,
      totalPrice: {
        curr: '',
        value: 0
      },
      debt: {
        curr: '',
        value: 0
      },
      paymentProcessed: false,
      serviceData: {
        isExpress: '',
        withHotel: 'Да',
        entries: -1
      },
      additionalService: {
        hotel: 0,
        arrivalCard: 0,
        insurance: 0
      }
    } as VisaPageDataType,
    reducers: {
      setVisaPageFormikDataAC(state, action: PayloadAction<FirstPageDataType | VisaPageDataType>) {
        // init objects create
        if (state.applicantsData.length === 0) {
          state.uid = cryptoRandomString({length: 10, type: 'alphanumeric'});
          state.applicantsData = [...new Array(+action.payload.numberOfApplicants)].map((el, index) => {
            el = Object.assign({}, new ApplicantData())
            if (index === 0) {
              el.mainApplicantIs = 'первый заявитель'
            }
            return el
          })
        }
        // if u goes back and change numberOfApplicants
        if (state.applicantsData.length !== +action.payload.numberOfApplicants) {
          let diff = +action.payload.numberOfApplicants - state.applicantsData.length
          if (diff > 0) {
            for (let i = 0; i < diff; i++) {
              state.applicantsData = [...state.applicantsData, Object.assign({}, new ApplicantData())]
            }
          } else {
            for (let i = 0; i < -diff; i++) {
              state.applicantsData.pop()
            }
          }
        }
        Object.assign(state, {...action.payload})
      },
      updateApplicationField(state, action: PayloadAction<
        | { visa_status: visaStatus }
        | { accountingData: [], pureInc: number }
        | { commentData: string }
        | { fullPrice: number }
      >) {
        Object.assign(state, action.payload)
      },
      updateApplicationAdditionService(state, action: PayloadAction<{
        serviceName: 'hotel' | 'arrivalCard' | 'insurance',
        value: number
      }>) {
        if (state.additionalService) {
          state.additionalService[action.payload.serviceName] = action.payload.value
        }
      },
      /*   updateApplicationPayment(state, action: PayloadAction<{
             date: string,
             currency: string,
             paymentType: string,
             value: number,
             receipt: Array<string>
         }>) {
             state.paymentData = action.payload
         },*/
      updateAccountingData(state, action: PayloadAction<AccountingData>) {
        if (!!state?.accountingData?.length) {
          state.accountingData.push({...action.payload, fees: []})
          state.pureInc += action.payload.income
        } else {
          const inc = action.payload.income - action.payload.fees.reduce((acc, el) => acc + el.value, 0)
          state.accountingData = [{...action.payload, income: inc}]
          state.pureInc = inc
        }
        state.paymentProcessed = false
      },
      delApplicationBadPhoto(state) {
        delete state.badPhotos
        return state
      },
      updateApplicantField(state, action: PayloadAction<{
        fieldName: string,
        value: string | Array<string> | { serviceNames: Array<string> } | Array<{
          country: string,
          date: string,
          address: string
        }> | Array<{
          hotelName: string,
          arrivalDate: string,
          departureDate: string,
          hotelAddress: string,
          bookingHotel: Array<string>
        }>,
        index: number
      }>) {
        state.applicantsData[action.payload.index][action.payload.fieldName] = action.payload.value
        return state
      },
      deleteCheckField(state, action: PayloadAction<{ fieldName: string, index: number }>) {
        delete state.applicantsData[action.payload.index].importantDataToCheck[action.payload.fieldName]
        return state
      },
      addCheckField(state, action: PayloadAction<{ fieldName: string, index: number }>) {
        state.applicantsData[action.payload.index].importantDataToCheck[action.payload.fieldName] = []
        return state
      },
      clearState(state) {
        return state = {
          uid: undefined,
          service: '',
          email: '',
          tel: '',
          visitPurpose: '',
          numberOfApplicants: '',
          fullPrice: 0,
          visa_status: '',
          file: [],
          applicantsData: [],
          appDate: '',
          commentData: '',
          pureInc: 0,
          totalPrice: {
            curr: '',
            value: 0
          },
          debt: {
            curr: '',
            value: 0
          },
          paymentProcessed: false,
          serviceData: {
            isExpress: '',
            withHotel: 'Да',
            entries: -1
          },
          additionalService: {
            hotel: 0,
            arrivalCard: 0,
            insurance: 0
          }
        }
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getVisaTC.fulfilled, (state, action) => {
          return action.payload
        })
        .addCase(getVisaForBadPhotoTC.fulfilled, (state, action) => {
          return action.payload
        })
    }
  }
)

export const visaPageReducer = slice.reducer;
export const {
  setVisaPageFormikDataAC,
  updateApplicantField,
  addCheckField,
  deleteCheckField,
  clearState,
  delApplicationBadPhoto,
  updateAccountingData,
  updateApplicationField,
  updateApplicationAdditionService
} = slice.actions

